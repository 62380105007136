<div class="map-selector">
  <div class="map-selector__background"></div>
  <h1>Cartes interactives</h1>
  <div class="map-selector__buttons-wrapper">
    <button class="map-selector__button" mat-button routerLink="/femmes">
      <mat-icon svgIcon="pin"></mat-icon>
      <span>Femmes remarquables du Québec</span>
    </button>
    <button class="map-selector__button" mat-button routerLink="/patriotes">
      <mat-icon svgIcon="pin"></mat-icon>
      <span>Figures marquantes du mouvement patriote</span>
    </button>
   <button class="map-selector__button" mat-button routerLink="/premiers-ministres">
    <mat-icon svgIcon="pin"></mat-icon>
      <span>Premiers ministres du Québec</span>
    </button>
    <button class="map-selector__button" mat-button routerLink="/autochtone">
    <mat-icon svgIcon="pin"></mat-icon>
      <span>Éléments du patrimoine toponymique autochtone du Québec</span>
    </button>
    <button class="map-selector__button" mat-button routerLink="/coups-de-coeur">
    <mat-icon svgIcon="pin"></mat-icon>
      <span>Toponymes coups de cœur</span>
    </button>
  </div>
</div>