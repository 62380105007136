import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MapSelectorComponent } from './map-selector/map-selector.component';
import { MapComponent } from './map-selector/map/map.component';

const routes: Routes = [
  {
    path: 'femmes', component: MapComponent, data: {
      title: 'Femmes remarquables du Québec - Carte interactive - Commission de toponymie',
      ogUrl: '/femmes',
      description: 'La carte interactive « Femmes remarquables du Québec » met en lumière quelque 500 noms de lieux rappelant plus de 100 femmes d’ici ayant eu un parcours hors du commun, que ce soit dans les domaines de la santé, de l’éducation, des affaires, du syndicalisme, de la justice sociale, des sciences, des arts, du sport ou de la politique.',
      mapContext: 'women',
    }
  },
  {
    path: 'patriotes', component: MapComponent, data: {
      title: 'Figures marquantes du mouvement patriote - Carte interactive - Commission de toponymie',
      ogUrl: '/patriotes',
      description: 'La carte interactive « Figures marquantes du mouvement patriote » met en lumière quelque 200 noms de lieux rappelant 12 personnalités politiques, intellectuelles ou sociales engagées dans le mouvement patriote québécois du XIXe siècle.',
      mapContext: 'patriots',
    }
  },
  {
    path: 'premiers-ministres', component: MapComponent, data: {
      title: 'Premiers ministres du Québec - Carte interactive - Commission de toponymie',
      ogUrl: '/premiers-ministres',
      description: 'La carte interactive « Premiers ministres du Québec » met en lumière quelque 450 noms de lieux rappelant les 25 premiers ministres québécois commémorés dans la toponymie.',
      mapContext: 'primeMinisters',
    }
  },
  {
    path: 'autochtone', component: MapComponent, data: {
      title: 'Éléments du patrimoine toponymique autochtone du Québec - Carte interactive - Commission de toponymie',
      ogUrl: '/autochtone',
      description: 'La carte interactive « Éléments du patrimoine toponymique autochtone du Québec » met en lumière des noms de lieux autochtones particulièrement significatifs qui ont été retenus par les représentants de communautés autochtones en raison de leur valeur sur le plan culturel.',
      mapContext: 'autochtones',
    }
  },
  {
    path: 'coups-de-coeur', component: MapComponent, data: {
      title: 'Toponymes coups de coeur - Carte interactive - Commission de toponymie',
      ogUrl: '/coups-de-coeur',
      description: 'coups de coeur',
      mapContext: 'coupsDeCoeur',
    }
  },
  {
    path: '', component: MapSelectorComponent, data: {
      title: 'Cartes interactives - Commission de toponymie',
      ogUrl: '/',
      description: 'Les cartes interactives de la Commission de toponymie permettent de visualiser des noms de lieux du Québec selon un thème précis. Selon leur disponibilité, différents renseignements sur les lieux nommés s’y trouvent, par exemple une description du lieu, la date de sa désignation, une photo, etc.'
    }
  },
  {
    path: '**', redirectTo: '/', pathMatch: 'full'
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
