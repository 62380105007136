<div class="highlighted-places-slider">
  <ng-container *ngIf="highlightedPlacesList.length > 1;then pluriel else singulier" ></ng-container>
  <ng-template #singulier>
    <h2 class="highlighted-places-slider__title">{{ ((mapContext$ | async) + '.highlighted-place-title') | translate }}</h2>
  </ng-template>
  <ng-template #pluriel>
    <h2 class="highlighted-places-slider__title">{{ ((mapContext$ | async) + '.highlighted-place-title-pluriel') | translate }}</h2>
  </ng-template>
  <div class="highlighted-places-slider__pagination swiper-pagination"></div>
  <div
    #sidenavSwiper
    *ngIf="upToDateSlider"
    [swiper]="swiperConfig"
    [(index)]="index"
    class="swiper-container"
    (update)="onSliderUpdate($event)">
    <div class="highlighted-places-slider__wrapper swiper-wrapper">
      <section
        class="highlighted-places-slider__slide swiper-slide"
        *ngFor="let highlightedPlace of highlightedPlacesList; trackBy: trackByFn">
        <header class="highlighted-places-slider__header">
          <h3>{{ highlightedPlace.name }}</h3>
          <button
            mat-icon-button
            *ngIf="highlightedPlace.prononciation"
            (click)="jouerPrononciation(highlightedPlace.prononciation)">
            <mat-icon svgIcon="volume"></mat-icon>
          </button>
          
          <h4 *ngIf="(mapContext$ | async) !== 'autochtones'">{{ highlightedPlace.description.city }}</h4>
        </header>

        <figure *ngIf="highlightedPlace.photo.src" class="highlighted-places-slider__figure" (click)="openFullScreenImg()">
          <mat-icon svgIcon="expand"></mat-icon>
          <img [src]="highlightedPlace.photo.src" [alt]="highlightedPlace.photo.alt" appNoRightClick/>
          <figcaption class="p-img-source">{{ highlightedPlace.photo.source }}</figcaption>
        </figure>

        <div class="highlighted-places-slider__content">
          <p [innerHTML]="highlightedPlace.description.bio"></p>

          <footer class="highlighted-places-slider__footer">
            <a
            [href]="highlightedPlace.link"
            target="_blank">{{ 'global.complete-place-bio' | translate }}</a>
          </footer>
        </div>
      </section>
    </div>
  </div>
  <ng-container *ngIf="highlightedPlacesList.length > 1 && !isMobile()">
    <div class="highlighted-places-slider__button sidebar-swiper-button-prev swiper-button-prev"></div>
    <div class="highlighted-places-slider__button sidebar-swiper-button-next swiper-button-next"></div>
  </ng-container>
</div>
