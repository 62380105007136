<div class="app-sidenav-description">
  <div class="h-resume">
    <header class="h-card p-contact">
      <h1 class="p-name">{{ celebrity.name.fullName }}</h1>

      <img [alt]="celebrity.avatar.alt" [src]="celebrity.avatar.src" class="u-photo" height="130px" width="130px" appNoRightClick/>

      <div class="p-experience h-event">
        <div *ngIf="displayFilters; else displayerOrder" class="dt-start dt-end">{{ celebrity.filter }}</div>

        <ng-template #displayerOrder>
          <h2 *ngIf="(mapContext$ | async) === 'primeMinisters'" class="p-name">
            <span>{{ celebrity.order }}</span>
            <sup *ngIf="celebrity.order === 1; else notFirst">er</sup>
            <ng-template #notFirst>
              <sup>e</sup>
            </ng-template>
            &nbsp;
            <span>premier ministre</span>
          </h2>
        </ng-template>
      </div>

    </header>

    <div class="app-sidenav-description__summary">
      <div *ngIf="displayMore; else displaySummary" [@accordionAnimation]>

        <p class="p-summary" [innerHTML]="celebrity.description.bio"></p>
        <a *ngIf="celebrity.link" target="_blank" [href]="celebrity.link">{{ ( (mapContext$ | async) + '.complete-person-bio') |
          translate }}</a>
        <span *ngIf="celebrity.avatar.source" class="p-img-source"> {{ 'global.picture-label' | translate }}{{ celebrity.avatar.source }}</span>
      </div>

      <ng-template #displaySummary>
        <p class="p-summary" [innerHTML]="celebrity.description.bio | multiLineEllipsis: 150"></p>
      </ng-template>

      <button mat-icon-button (click)="toggleBioDisplay()">
        <ng-container *ngIf="displayMore; else displaySummaryLabel">
          {{ 'global.less' | translate }}
          <mat-icon svgIcon="minus"></mat-icon>
        </ng-container>

        <ng-template #displaySummaryLabel>
          {{ 'global.more' | translate }}
          <mat-icon svgIcon="plus"></mat-icon>
        </ng-template>
      </button>
    </div>
  </div>

  <hr />
</div>
