<button
  (click)="profilSelected()"
  class="reel-profil"
  role="button"
  type="button">
  <div class="reel-profil__name-wrapper">
    <span class="reel-profil__name">
      {{celebrity.name.firstName}}<br/>{{celebrity.name.lastName}}
    </span>
  </div>

  <figure
    *ngIf="(mapContext$ | async) as mapContext"
    class="reel-profil__figure"
    [ngClass]="{'reel-profil__figure--selected': isSelected, 'reel-profil__figure--no-caption' : mapContext === 'patriots' || mapContext === 'autochtones' }">
    <img
      [src]="celebrity.avatar.src"
      [alt]="celebrity.avatar.alt"
      [ngClass]="{'reel-profil__avatar--bordure': mapContext !== 'coupsDeCoeur', 'reel-profil__avatar--selected': isSelected }"
      class="reel-profil__avatar"
      height="80px"
      width="80px"
      appNoRightClick />

        <figcaption *ngIf="mapContext === 'women'" class="reel-profil__figcaption reel-profil__figcaption--aligned">
          <span class="reel-profil__figcaption-filter">{{ celebrity.filter }}</span>
        </figcaption>

        <figcaption  *ngIf="mapContext === 'primeMinisters'" class="reel-profil__figcaption">
          <span>{{ celebrity.order }}</span>
          <sup *ngIf="celebrity.order === 1; else notFirst">er</sup>
          <ng-template #notFirst>
            <sup>e</sup>
          </ng-template>
        </figcaption>

   </figure>
</button>
